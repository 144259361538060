import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import lupa from "../../assets/img/sistema/lupa.svg";

import { ObjIsEmpty } from "../../utils/helper";

import SelectCustom from "../../components/SelectCustom";
import contrato from "../../assets/img/sistema/contrato.svg";

import { Container } from "./styles";

const options = [
  { value: 1, label: "Ação 1" },
  { value: 2, label: "Ação 2" },
  { value: 3, label: "Ação 3" },
];

function FiltroContrato(props) {
  const { setItemSelect, select, placeholder, handleSearhChange } = props;

  const history = useHistory();

  const {
    location: { itemselect },
  } = history;

  const { dados } = useSelector((state) => state.gerentePlataforma);
  const { tipoAcao } = useSelector((state) => state.tipoAcao);

  const [selectedItem, setSelectedItem] = useState();

  const { countApproved, countWainting, countSemVinculo } = useMemo(() => {
    if (!dados) return 0;

    const result = ObjIsEmpty(selectedItem ?? {})
      ? dados[itemselect?.label]
        ? dados[itemselect?.label]
        : []
      : dados[selectedItem.label]
      ? dados[selectedItem.label]
      : [];

    const values = [];
    Object.values(result).forEach((item) => {
      values.push(...item);
    });

    const countApproved = values
      .filter((item) => item.status.id === 1)
      .reduce((accumulator) => accumulator + 1, 0);

    const countWainting = values
      .filter((item) => item.status.id === 1 || item.status.id === 2)
      .reduce((accumulator) => accumulator + 1, 0);

    const countSemVinculo = values
      .filter((item) => item.status.id === 3)
      .reduce((accumulator) => accumulator + 1, 0);

    return { countApproved, countWainting, countSemVinculo };
  }, [dados, itemselect, selectedItem]);

  return (
    <>
      <Container>
        <div className="container-select">
          <SelectCustom
            label={placeholder}
            options={select.length ? select : options}
            change={(value) => {
              setSelectedItem(value);
              setItemSelect(value);
            }}
          />
        </div>
      
        <div className="container-input">
          <input
            type="text"
            placeholder={tipoAcao === "financiamento_valor_fixo" ? "Digite o nº do contrato e ou código da revenda" : "Digite o nº do contrato, grupo econômico ou código da revenda"}
            onChange={(event) => handleSearhChange(event.currentTarget.value)}
          />
          <img src={lupa} alt="" />
        </div>

        <div className="detalhes">
          <div className="container-contratos">
            <img src={contrato} alt="" />
            <p>Contratos &gt;</p>
          </div>
          <div className="container-contratos">
            <p>Disponíveis: {countWainting + countSemVinculo}</p>
          </div>

          <div className="container-contratos">
            <p>Não vinculados: {countSemVinculo}</p>
          </div>

          <div className="container-contratos">
            <p>Vinculados: {countWainting}</p>
          </div>

          <div className="container-contratos">
            <p>Aprovados: {countApproved}</p>
          </div>
        </div>
      </Container>
  </>
  );
}

export default FiltroContrato;
